
<template>
    <div class="post schule btm-menu">
        <Breadcrumb :background="true" content="Kita- & Schulfinder | Schule"/>

        <div class="profile">
            <div class="container-fluid">
                <div class="profile-main">

                    <profile-image-carousel v-if="slides!==null" :slides="slides"/>

                    <div class="profile-main__info">
                        <h5 v-if="traeger!=''">{{traeger}}</h5>
                        <share-button v-if="!showShareBtnInTitle"/>
                        <div class="profile-main__info-title">
                            <h3>{{title}}</h3>
                            <share-button v-if="showShareBtnInTitle"/>
                        </div>
                        <info-block target="desktop" :content="content" :infos="infoBlockInfos"/>

                    </div>
                </div>
            </div>
        </div>

        <div class="section about">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <div class="row">
                            <div class="col-12">
                                <div class="about-content d-lg-none">
                                    <p v-if="showMoreDescription" v-html="shortDescription"></p>
                                    <p v-else v-html="description"></p>
                                    <button
                                    v-if="shortDescription !== ''"
                                    @click="showMoreDescription = !showMoreDescription"
                                    class="btn btn-block mt10 btn-link text-primary">
                                    {{ showMoreDescription ? 'MEHR ANZEIGEN' : 'WENIGER ANZEIGEN' }}
                                </button>
                            </div>
                            <div class="about-content d-none d-lg-block">
                                <h5 class="post-heading">Beschreibung</h5>
                                <p v-html="description"></p>
                            </div>
                        </div>

                        <info-block class="col-12 d-lg-none" target="mobile" :content="content" :infos="infoBlockInfos"/>

                        <div class="col-12 d-lg-none" v-if="anmeldung!==''">
                            <div class="card-simple mt40 mb20 mt-lg-0">
                                <h5 class="card-simple-title">ANmeldung</h5>
                                <p class="text-black" v-html="anmeldung"></p>
                            </div>
                        </div>

                        <div class="col-12">
                           <div class="key">
                            <h5 class="post-heading">ECKDATEN</h5>
                            <ul class="key-list">
                                <li class="key-list__item" v-if="abschluesse!=''">
                                    <h5>Abschlüsse</h5>
                                    <p>{{Array.isArray(abschluesse)?abschluesse.join(', '):abschluesse}}</p>
                                </li>
                                <li class="key-list__item" v-if="konfession!=''">
                                    <h5>Konfession</h5>
                                    <p>{{Array.isArray(konfession)?konfession.join(', '):konfession}}</p>
                                </li>
                                <li class="key-list__item" v-if="ganztagsangebot!=''">
                                    <h5>Ganztagsangebot</h5>
                                    <p>{{Array.isArray(ganztagsangebot)?ganztagsangebot.join(', '):ganztagsangebot}}</p>
                                </li>
                                <li class="key-list__item" v-if="besonderePaedagogischeKonzepte!=''">
                                    <h5>Besondere pädagogische Konzepte</h5>
                                    <p>{{Array.isArray(besonderePaedagogischeKonzepte)?besonderePaedagogischeKonzepte.join(', '):besonderePaedagogischeKonzepte}}</p>
                                </li>
                                <li class="key-list__item" v-if="fremdsprachen!=''">
                                    <h5>Fremdsprachen</h5>
                                    <p>{{Array.isArray(fremdsprachen)?fremdsprachen.join(', '):fremdsprachen}}</p>
                                </li>
                                <li class="key-list__item" v-if="herkunftssprachlicherUnterricht!=''">
                                    <h5>Herkunftssprachlicher Unterricht</h5>
                                    <p>{{Array.isArray(herkunftssprachlicherUnterricht)?herkunftssprachlicherUnterricht.join(', '):herkunftssprachlicherUnterricht}}</p>
                                </li>
                                <li class="key-list__item" v-if="bilingualerUnterricht!=''">
                                    <h5>Bilingualer Unterricht</h5>
                                    <p>{{Array.isArray(bilingualerUnterricht)?bilingualerUnterricht.join(', '):bilingualerUnterricht}}</p>
                                </li>
                                <li class="key-list__item" v-if="besondereSprachlicheAngebote!=''">
                                    <h5>Besondere sprachliche Angebote</h5>
                                    <p>{{Array.isArray(besondereSprachlicheAngebote)?besondereSprachlicheAngebote.join(', '):besondereSprachlicheAngebote}}</p>
                                </li>
                                <li class="key-list__item" v-if="schwerpunkte!=''">
                                    <h5>Schwerpunkte</h5>
                                    <p>{{Array.isArray(schwerpunkte)?schwerpunkte.join(', '):schwerpunkte}}</p>
                                </li>
                                <li class="key-list__item" v-if="zertifikate!=''">
                                    <h5>Zertifikate</h5>
                                    <p v-html="zertifikate"></p>
                                </li>
                                <li class="key-list__item" v-if="kooperationen!=''">
                                    <h5>Kooperationen</h5>
                                    <p v-html="kooperationen"></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-lg-3 ml-auto">
                <div class="card-simple mt-lg-0 d-none d-lg-block" v-if="anmeldung!==''">
                    <h5 class="card-simple-title">Anmeldung</h5>
                    <p class="text-black" v-html="anmeldung"></p>
                </div>

                <div class="card-simple mt20" v-if="weitereInfos!==''">
                    <h5 class="card-simple-title">Weitere Infos</h5>
                    <p class="text-black" v-html="weitereInfos"></p>
                </div>


                <a class="btn btn-block btn-w-icon icon-left mt20 btn-secondary" target="_blank" :href="flyerLink" v-if="flyerLink!==null">
                    <span class="material-icons-outlined" aria-hidden="true">file_download</span>
                    Flyer herunterladen
                </a>
            </div>
        </div>
    </div>
</div>

<div class="section pt-lg-0">
    <div class="container-fluid">
        <div class="row">

            <div class="col-12 col-lg-6">
                <detail-map v-if="content !== null && address !== null" :content="content" :addressObj="address"></detail-map>
            </div>

            <div class="col-12 col-lg-6">
                <contact-block :contentId="content.id" class="mt40 mt-lg-0" :contact="contactInfo" v-if="contactInfo!=null && content!==null"/>
            </div>
        </div>
    </div>
</div>

</div>
</template>

<script>
    import Resource from '@/api/resource';
    import { getContentsOfType } from '@/api/content';
    import { getFieldValues, showLoader, hideLoader, getFlyerLink } from '@/utils/helpers';
    const contentResource = new Resource('contents');
    import { screenSizeMixin } from '@/mixins/screenSizeMixin';

    export default {
        name: 'SchulDetail',
        mixins: [screenSizeMixin],
        components: {
            ShareButton: () => import('@/components/controls/ShareButton.vue'),
            InfoBlock: () => import('@/components/controls/InfoBlock.vue'),
            ProfileImageCarousel: () => import('@/components/controls/ProfileImageCarousel.vue'),
            DetailMap: () => import('@/components/controls/DetailMap.vue'),
            ContactBlock: () => import('@/components/controls/ContactBlock.vue'),
            Breadcrumb: () => import('@/components/layout/Breadcrumb.vue'),
        },
        data() {
            return {
                content: null,
                showMoreDescription: false,
                showMoreInfo: true,
                keyData: [
                [
                'Abschlüsse',
                'MSA nach Stufe 10, Fachabitur, Allgemeine Hochschulreife (Abitur)',
                ],
                [
                'Ganztagsangebot',
                'Offene Ganztagsschule',
                ],
                [
                'Sprachen',
                'Englisch, Französisch, Spanisch, Latein, Alt-Griechisch (AG), Russisch (AG)',
                ],
                [
                'Barrierefreiheit',
                'Teilweise barrierefrei',
                ],
                [
                'Pädagogische Unterstützung',
                'Sozialarbeiter, Hausaufgabenhilfe',
                ],
                [
                'Ausstattung',
                'Digitale Tafeln, Tablets, Laptops, Sportflächen',
                ],
                [
                'Kooperationen',
                'Partnerschule in Frankreich (Lyon)',
                ],
                [
                'Zertifikate',
                'Teilnehmer Urkunde BJS',
                ],
                ],
                offers: [
                {
                    imageIcon: '/assets/icons/category/category-1.png',
                    category: 'Sport & Bewegung',
                    date: 'Do, 30.09.2021',
                    title: 'Skateboard-Treff für Kinder & Jugendliche',
                    time: '12:00 - 14:00 Uhr',
                    location: [
                    'Jugendtreff Wolfsburg',
                    'Wolfsburger Straße 99'
                    ],
                    price: 'kostenfrei',
                    link: '/angebote/xyz-slug'
                },
                {
                    imageIcon: '/assets/icons/category/category-2.png',
                    category: 'Kreatives & Musik',
                    date: 'Do, 30.09.2021',
                    title: 'Malen mit Acrylfarben',
                    time: '12:00 - 14:00 Uhr',
                    location: [
                    'Kunsthalle Wolfsburg',
                    'Wolfsburger Straße 99'
                    ],
                    price: '25€ pro Person',
                    link: '/angebote/xyz-slug'
                },
                {
                    imageIcon: '/assets/icons/category/category-5.png',
                    category: 'Natur & Umwelt',
                    date: 'So, 03.10.2021',
                    title: 'Insektenhotel & co: den Garten insektenfreundlich gestalten',
                    time: '12:00 - 14:00 Uhr',
                    location: [
                    'Gartenverein Nordstadt',
                    'Wolfsburger Straße 99'
                    ],
                    price: '5€',
                    link: '/angebote/xyz-slug'
                },
                {
                    imageIcon: '/assets/icons/category/category-5.png',
                    category: 'Natur & Umwelt',
                    date: 'Do, 30.09.2021',
                    title: 'Den Garten winterfest machen',
                    time: '12:00 - 14:00 Uhr',
                    location: [
                    'Gartenverein Nordstadt',
                    'Wolfsburger Straße 99'
                    ],
                    price: '5€',
                    link: '/angebote/xyz-slug'
                },
                ],
                wishlists: [
                {
                    imageIcon: '/assets/icons/category/category-1.png',
                    category: 'Sport & Bewegung',
                    title: 'Aktiv in Wolfsburg: Kinder & Jugendliche',
                    desc: 'Fußballverein WOB-Mitte, Bildungsbüro Wolfsburg, VfL Wolfsburg, Sportverein A, Sportverein B, Sportverein C',
                    link: '/merkliste/xyz-slug'
                },
                {
                    imageIcon: '/assets/icons/category/category-1.png',
                    category: 'Sport & Bewegung',
                    title: 'Turniersommer 2021',
                    desc: 'Fußballverein WOB-Mitte, Bildungsbüro Wolfsburg, VfL Wolfsburg, Sportverein A, Sportverein B, Sportverein C',
                    link: '/merkliste/xyz-slug'
                },
                {
                    imageIcon: '/assets/icons/category/category-1.png',
                    category: 'Sport & Bewegung',
                    title: 'Turniersommer 2021',
                    desc: 'Fußballverein WOB-Mitte, Bildungsbüro Wolfsburg, VfL Wolfsburg, Sportverein A, Sportverein B, Sportverein C',
                    link: '/merkliste/xyz-slug'
                },
                ]
            }
        },
        watch: {
          id(newId, oldId) {
            if (newId!=oldId) {
              this.getContent();
              this.isActiveLocation=true;
              this.$scrollTo();
          }
      }
  },
  created() {
      this.getContent();
      this.isActiveLocation=true;
  },
  computed: {
    id() {
        return this.$route.params.id;
    },
    title(){
        if(this.content!=null){
          return this.content.title;
      }
      return "";
  },
  description(){
    var desc = getFieldValues(this.content,'body');
    return desc!=null?desc:'';
},
anmeldung(){
    var value = getFieldValues(this.content,'anmeldung');
    return value!=null?value:'';
},
abschluss(){
    var value = getFieldValues(this.content,'abschluss');
    return value!=null?value:'';
},
fremdsprachen(){
    var value = getFieldValues(this.content,'fremdsprachen');
    return value!=null?value:'';
},
herkunftssprachlicherUnterricht(){
    var value = getFieldValues(this.content,'herkunftssprachlicher_unterricht');
    return value!=null?value:'';
},
herkunftssprachlicherUnterricht(){
    var value = getFieldValues(this.content,'herkunftssprachlicher_unterricht');
    return value!=null?value:'';
},
bilingualerUnterricht(){
    var value = getFieldValues(this.content,'bilingualer_unterricht');
    return value!=null?value:'';
},
besondereSprachlicheAngebote(){
    var value = getFieldValues(this.content,'besondere_sprachliche_angebote');
    return value!=null?value:'';
},
besonderePaedagogischeKonzepte(){
    var value = getFieldValues(this.content,'besondere_pdagogische_konzepte');
    return value!=null?value:'';
},
schwerpunkte(){
    var value = getFieldValues(this.content,'schwerpunkte');
    return value!=null?value:'';
},
kooperationen(){
    var value = getFieldValues(this.content,'kooperationen');
    return value!=null?value:'';
},
zertifikate(){
    var value = getFieldValues(this.content,'zertifikate');
    return value!=null?value:'';
},
abschluesse(){
    var value = getFieldValues(this.content,'abschluss');
    return value!=null?value:'';
},
traeger(){
    var value = getFieldValues(this.content,'trgerschaft');
    return value!=null?value:'';
},
ganztagsangebot(){
    var value = getFieldValues(this.content,'ganztagsangebot');
    return value!=null?value:'';
},
konfession(){
    var value = getFieldValues(this.content,'konfession');
    return value!=null?value:'';
},
schulart(){
    var value = getFieldValues(this.content,'schulart');
    if(value!=null){
        if(Array.isArray(value) && value.length>0){
            return value[0];
        }
        return value;
    }
    return null;
},
infoBlockInfos(){
    var object = {};
    object.address = this.address;
    object.appointments = getFieldValues(this.content,'ffnungszeiten');

    object.linkTo = {
        url: getFieldValues(this.content,'webseite'),
    }
    return object;
},
address(){
    var address = getFieldValues(this.content,'adresse');
    return address!==null?address:'';
},
teaserImages(){
    var pictures = getFieldValues(this.content,'teaser-bilder');
    if(pictures != null){
      if(Array.isArray(pictures)){
        return pictures;
    }
    else{
        return [pictures];
    }
}
return null;
},
logo(){
    var logo = getFieldValues(this.content,'logo');
    if(logo!=null){
      logo = logo.charAt(0)!="/"?"/"+logo:logo;
  }
  return logo;
},
contactInfo(){
    var contact = getFieldValues(this.content,'kontaktinformationen');
    return contact!==null?contact:null;
},
weitereInfos(){
    var value = getFieldValues(this.content,'weitere_infos');
    return value!=null?value:'';
},
flyerLink(){
    return getFlyerLink(getFieldValues(this.content,'flyer'),this.$backendUrl);
},
slides(){
    var images = [];
    if(this.logo != null){
        var item = {
            class: 'logo',
            image: this.$backendUrl+this.logo,
        };
        images.push(item);
    }

    if(this.teaserImages != null){
      for(var i = 0; i < this.teaserImages.length; i++){
        var item = {
            class: 'item',
            image: this.$backendUrl+this.teaserImages[i],
        };
        images.push(item);
    }
}

/*add the logo as the first image to the array*/
if(images.length == 0){
    return null;
}
return images;
},
showShareBtnInTitle(){
    if(this.$isMobileScreen){
        return true;
    }
    return false;
},
shortDescription() {
    if (this.description.length > this.$shortDescriptionWordCount) {
        this.showMoreDescription = true;

        return this.description.substring(0, this.$shortDescriptionWordCount) + '...';
    }
    return '';
},
},
methods:{
 getFieldValues, 
 showLoader, 
 hideLoader, 
 getContent() {
  this.loader = this.showLoader(this.loader);
  contentResource.get(this.id)
  .then(response => {
    this.content = response.data;
})
  .finally(() => {
    this.loader = this.hideLoader(this.loader);
});
},
}
}
</script>
<style lang="scss">
    @import '@/scss/_mixins.scss';
    @import '@/scss/_variables.scss';

    .profile{

        .container-fluid{
            @media (max-width: 991px) {
                padding-left: 0px;
                padding-right: 0px;
                margin-left: 0px;
                margin-right: 0px;
            }

            .profile-main__info {

              @media (max-width: 1360px) {
                  min-width: 540px;
                  margin-right: 60px;
              }

              @media (max-width: 991px) {
                padding-right: 15px;
                padding-left: 15px;
                margin-right: auto;
                margin-left: auto;
            }

            @media (max-width: 555px) {
                min-width: auto;
            }

            .info-list {
                margin-right: 20px;
            }

            h5 {
                @media (max-width: 991px) {
                    margin-bottom: 0px;
                }
            }
        }

    }
}

</style>